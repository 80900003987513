import React, { useEffect, useState } from "react";
import type { FC } from "react";
import {
  Link as RouterLink,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Card,
  FormLabel,
  CardContent,
  Tabs,
  Tab,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Select,
  TextField,
  Typography,
  AutocompleteRenderGroupParams,
} from "@material-ui/core";
import useSettings from "../../hooks/useSettings";
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { FilePondErrorDescription, FilePondFile } from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { VariableSizeList, ListChildComponentProps } from "react-window";

import { useSelector as useReduxSelector } from "../../store";

import styled from "styled-components";

import JSZip, { folder } from "jszip";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";

import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import GraphqlClient from "../../client/graphql.client";
import { FileConIntero, InputRichiestaPreventivi, InputRichiestaPreventiviConDocumentoSingolo } from "src/types/generated";

import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { DatePicker, LocalizationProvider } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import itLocale from "date-fns/locale/it";
import { format } from "date-fns";
import { creaRichiesta, creaRichiestaConDocumentoSingolo } from "./commands.richieste";

// filepond plugin
registerPlugin(FilePondPluginFileValidateType);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

const tabs = [
  { label: "Elenco documenti", value: "elencoDocumenti" },
  { label: "Documento singolo", value: "documentoSingolo" },
];

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactNode) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

const NuovaRichiesta: FC = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const configuration = useReduxSelector((state) => state.configuration);

  const handleNext = () => {
    if (fileDocReddituali.length === 0 && activeStep === 1) {
      enqueueSnackbar("Hai dimenticato di inserire documenti obbligatori", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
      return;
    }
    if (filePrivacySMC.length === 0 && activeStep === 2) {
      enqueueSnackbar("Hai dimenticato di inserire documenti obbligatori", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
      return;
    }
    if (fileDocAnagrafici.length === 0 && activeStep === 0) {
      enqueueSnackbar("Hai dimenticato di inserire documenti obbligatori", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [currentTab, setCurrentTab] = useState<string>("elencoDocumenti");

  const [fileDocReddituali, setFileDocReddituali] = useState([]);
  const [filePrivacySMC, setFilePrivacySMC] = useState([]);
  const [fileDocAnagrafici, setFileDocAnagrafici] = useState([]);
  const [fileDocIntegrativi, setFileDocIntegrativi] = useState([]);

  const [fileSingolo, setFileSingolo] = useState([]);

  const [inizioDocumentiReddituali, setInizioDocumentiReddituali] = useState(0);
  const [inizioPrivacySMC, setInizioPrivacySMC] = useState(0);
  const [inizioDocumentiAnagrafici, setInizioDocumentiAnagrafici] = useState(0);
  const [inizioDocumentiIntegrativi, setInizioDocumentiIntegrativi] = useState(0);
  const [fineDocumentiReddituali, setFineDocumentiReddituali] = useState(0);
  const [finePrivacySMC, setFinePrivacySMC] = useState(0);
  const [fineDocumentiAnagrafici, setFineDocumentiAnagrafici] = useState(0);
  const [fineDocumentiIntegrativi, setFineDocumentiIntegrativi] = useState(0);

  const [base64zip, setB64zip] = useState("");
  const [FilesInfo, setFilesInfo] = useState([]);

  const handleTabsChange = (event, value: string): void => {
    setCurrentTab(value);
  };

  const creaZip = async () => {
    // creo lo zip di con tutti i files
    let files = fileDocReddituali.concat(
      filePrivacySMC,
      fileDocAnagrafici,
      fileDocIntegrativi
    );
    var zip = new JSZip();
    for (let i = 0; i < files.length; i++) {
      zip.file(files[i].file.name, files[i].file);
    }

    // console.log('ZIPPPPPPPPPPPP', zip);
    // creo la struttura files da mandare in input
    /*
                RichiestaPreventivoDocAnagrafici = 350,
                RichiestaPreventivoDocReddituali = 351,
                RichiestaPreventivoDocIntegrativi = 352,
                RichiestaPreventivoPrivacySmcFirmata = 353,
    */

    let listInfoFiles: FileConIntero[] = [];
    fileDocAnagrafici.map((elem) => {
      let fileInfo = {
        nomeFile: elem.file.name,
        tipoDocumento: 350,
        fileType: "pdf",
      };
      listInfoFiles.push(fileInfo);
    });

    fileDocIntegrativi.map((elem) => {
      let fileInfo = {
        nomeFile: elem.file.name,
        tipoDocumento: 352,
        fileType: "pdf",
      };
      listInfoFiles.push(fileInfo);
    });
    fileDocReddituali.map((elem) => {
      let fileInfo = {
        nomeFile: elem.file.name,
        tipoDocumento: 351,
        fileType: "pdf",
      };
      listInfoFiles.push(fileInfo);
    });
    filePrivacySMC.map((elem) => {
      let fileInfo = {
        nomeFile: elem.file.name,
        tipoDocumento: 353,
        fileType: "pdf",
      };
      listInfoFiles.push(fileInfo);
    });

    setFilesInfo(listInfoFiles);
    const content = await zip.generateAsync({ type: "base64" });

    setB64zip(content);
    return { zip: content, listInfoFiles: listInfoFiles };
  };

  const classes = useStyles();

  function getSteps() {
    return [
      "Documenti anagrafici *",
      "Documenti reddituali *",
      "Modulistica precontrattuale *",
      "Documenti integrativi",
    ];
  }

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <div>
            <FilePond
              files={fileDocAnagrafici}
              allowMultiple={false}
              onupdatefiles={setFileDocAnagrafici}
              allowFileTypeValidation
              fileValidateTypeLabelExpectedTypes="{allTypes}"
              name="docAnagrafici"
              acceptedFileTypes={["application/pdf"]}
              labelIdle='Trascina e lascia il pdf o <span class="filepond--label-action">Cerca</span>'
            />
          </div>
        );
      case 1:
        return (
          <div>
            <FilePond
              files={fileDocReddituali}
              allowMultiple={false}
              onupdatefiles={setFileDocReddituali}
              allowFileTypeValidation
              fileValidateTypeLabelExpectedTypes="{allTypes}"
              name="docReddituali"
              acceptedFileTypes={["application/pdf"]}
              labelIdle='Trascina e lascia il pdf o <span class="filepond--label-action">Cerca</span>'
            //required={true}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <FilePond
              files={filePrivacySMC}
              allowMultiple={false}
              onupdatefiles={setFilePrivacySMC}
              allowFileTypeValidation
              fileValidateTypeLabelExpectedTypes="{allTypes}"
              name="privacySMC"
              acceptedFileTypes={["application/pdf"]}
              labelIdle='Trascina e lascia il pdf o <span class="filepond--label-action">Cerca</span>'
            />
          </div>
        );
      case 3:
        return (
          <div>
            <FilePond
              files={fileDocIntegrativi}
              allowMultiple={false}
              onupdatefiles={setFileDocIntegrativi}
              allowFileTypeValidation
              fileValidateTypeLabelExpectedTypes="{allTypes}"
              name="docIntegrativi"
              acceptedFileTypes={["application/pdf"]}
              labelIdle='Trascina e lascia il pdf o <span class="filepond--label-action">Cerca</span>'
            />
          </div>
        );
      default:
        return "Unknown step";
    }
  }

  const arrayTipoImpiego =
    (configuration?.itemsForSelects &&
      configuration?.itemsForSelects["tipoImpiegoSemplificato"]) ||
    [];
  const arrayLuoghi =
    (configuration?.itemsForSelects &&
      configuration?.itemsForSelects["comuni"]) ||
    [];
  const arrayLuoghiCodice =
    (configuration?.itemsForSelects &&
      configuration?.itemsForSelects["comuni"].map((item) => item.codice)) ||
    [];

  return (
    <>
      <Helmet>
        <title>Nuova Richiesta</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Nuova richiesta
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/richieste"
                  variant="subtitle2"
                >
                  Richieste
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Nuova richiesta
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<ArrowLeftIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                  to="/richieste"
                  variant="outlined"
                >
                  Indietro
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Formik
              initialValues={{
                nome: "",
                cognome: "",
                luogoNascita: null,
                provinciaNascita: null,
                dataNascita: "",
                sesso: "",
                codiceFiscale: "",
                telefono: "",
                cellulare: "",
                importo: 0,
                tipoImpiego: "",
                dataAssunzione: "",
                nota: "",
                email: "",
                submit: null,
              }}
              /*  validationSchema={
                                               Yup
                                                   .object()
                                                   .shape({
                                                       nome: Yup.string().max(30).required('Campo obbligatorio'),
                                                       cognome: Yup.string().max(30).required('Campo obbligatorio'),
                                                       luogoNascita: Yup.string().max(30).required('Campo obbligatorio'),
                                                       provinciaNascita:  Yup.string().max(30).required('Campo obbligatorio'),
                                                       //dataNascita: Yup.string().max(30).required('Campo obbligatorio'),
                                                       //sesso: Yup.string().max(30).required('Campo obbligatorio'),
                                                       codiceFiscale: Yup.string().length(16).required('Campo obbligatorio di 16 caratteri'),
                                                       importo: Yup.number().required('Campo obbligatorio'),
                                                       //tipoImpiego: Yup.string().max(30).required('Campo obbligatorio'),
                                                       //dataAssunzione: Yup.date().required('Obbligatorio').required('Campo obbligatorio'),
                                                       note: Yup.string().max(30).required('Campo obbligatorio'),
                                                       telefono: Yup.string().max(30).required('Campo obbligatorio'),
                                                       cellulare: Yup.string().max(30).required('Campo obbligatorio'),
                                                       email: Yup.string().max(30).required('Campo obbligatorio'),
                                                       nota: Yup.string().max(30).required('Campo obbligatorio'),
                                                   })
                                           } */
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ): Promise<void> => {
                //console.log("avviato submit")
                setSubmitting(true);

                if (currentTab === "elencoDocumenti") {
                  const content = await creaZip();

                  let mbSize = (content.zip.length - 1) / Math.pow(1024, 2);
                  if (mbSize > 30) {
                    enqueueSnackbar(
                      `Allegati superiori a 30 MB: (${mbSize.toFixed(
                        1
                      )}MB)  impossibile procedere.`,
                      {
                        anchorOrigin: {
                          horizontal: "right",
                          vertical: "top",
                        },
                        variant: "error",
                      }
                    );
                    return;
                  }

                  let inputForm: InputRichiestaPreventivi = {
                    cognome: values.cognome,
                    nome: values.nome,
                    dataNascita: values.dataNascita
                      ? moment(new Date(values.dataNascita)).format(
                        "YYYY-MM-DD"
                      ) + "T00:00:00Z"
                      : null,
                    codiceFiscale: values.codiceFiscale,
                    sesso: values.sesso,
                    cittaNascita: values.luogoNascita.codice,
                    provinciaNascita: values.provinciaNascita.description,
                    tipoImpiego: Number(values.tipoImpiego),
                    dataAssunzione: values.dataAssunzione
                      ? moment(new Date(values.dataAssunzione)).format(
                        "YYYY-MM-DD"
                      ) + "T00:00:00Z"
                      : null,
                    importoRichiesto: values.importo,
                    telefono: values.telefono,
                    cellulare: values.cellulare,
                    email: values.email,
                    nota: values.nota,
                    allegati: {
                      base64zip: content.zip,
                      files: content.listInfoFiles,
                    },
                  };

                  //const { nome, cognome, dataNascita, sesso, cittaNascita, provinciaNascita, codiceFiscale } = inputForm;
                  //console.log("form", { nome, cognome, dataNascita, sesso, cittaNascita, provinciaNascita, codiceFiscale })

                  const res = await creaRichiesta(inputForm);

                  if (res.error) {
                    console.log("ERRORE", res.message);

                    let errore = "Errore generico";
                    try {
                      if (res.message.length > 0) {
                        let err = JSON.parse(res.message[0].message).status;
                        errore = `(${err.status})[${err.errorMessage}]`;
                      }
                    } catch (e1) {
                      console.error("E!!!", e1);
                    }

                    enqueueSnackbar(
                      "Impossibile creare la richiesta: " + errore,
                      {
                        anchorOrigin: {
                          horizontal: "right",
                          vertical: "top",
                        },
                        variant: "error",
                      }
                    );
                    setSubmitting(false);
                    return;
                  }

                  enqueueSnackbar("Richiesta creata con successo", {
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top",
                    },
                    variant: "success",
                  });
                  setSubmitting(false);
                  navigate("/richieste");
                } else {
                  // TODO: Finire questa api
                  console.log('FILE SINGOLO', fileSingolo)

                  if (fileSingolo.length == 0) {
                    enqueueSnackbar(
                      "Devi inserire un pdf valido",
                      {
                        anchorOrigin: {
                          horizontal: "right",
                          vertical: "top",
                        },
                        variant: "error",
                      }
                    );
                    setSubmitting(false);
                    return;
                  }

                  const content = fileSingolo[0].getFileEncodeBase64String();
                  let mbSize = (content.length - 1) / Math.pow(1024, 2);
                  console.log("mbSize", mbSize)
                  if (mbSize > 30) {
                    enqueueSnackbar(
                      `Allegati superiori a 30 MB: (${mbSize.toFixed(
                        1
                      )}MB)  impossibile procedere.`,
                      {
                        anchorOrigin: {
                          horizontal: "right",
                          vertical: "top",
                        },
                        variant: "error",
                      }
                    );
                    return;
                  }

                  let inputForm: InputRichiestaPreventiviConDocumentoSingolo = {
                    cognome: values.cognome,
                    nome: values.nome,
                    dataNascita: values.dataNascita
                      ? moment(new Date(values.dataNascita)).format(
                        "YYYY-MM-DD"
                      ) + "T00:00:00Z"
                      : null,
                    codiceFiscale: values.codiceFiscale,
                    sesso: values.sesso,
                    cittaNascita: values.luogoNascita.codice,
                    provinciaNascita: values.provinciaNascita.description,
                    tipoImpiego: Number(values.tipoImpiego),
                    dataAssunzione: values.dataAssunzione
                      ? moment(new Date(values.dataAssunzione)).format(
                        "YYYY-MM-DD"
                      ) + "T00:00:00Z"
                      : null,
                    importoRichiesto: values.importo,
                    telefono: values.telefono,
                    cellulare: values.cellulare,
                    email: values.email,
                    nota: values.nota,
                    allegato: {
                      inizioDocumentiAnagrafici,
                      fineDocumentiAnagrafici,
                      pdfBase64: content,
                      inizioDocumentiIntegrativi,
                      fineDocumentiIntegrativi,
                      inizioDocumentiReddituali,
                      fineDocumentiReddituali,
                      inizioPrivacySMC,
                      finePrivacySMC,
                    }
                  };

                  const res = await creaRichiestaConDocumentoSingolo(inputForm);

                  if (res.error) {
                    console.log("ERRORE", res.message);

                    let errore = "Errore generico";
                    try {
                      if (res.message.length > 0) {
                        let err = JSON.parse(res.message[0].message).status;
                        errore = `(${err.status})[${err.errorMessage}]`;
                      }
                    } catch (e1) {
                      console.error("E!!!", e1);
                    }

                    enqueueSnackbar(
                      "Impossibile creare la richiesta: " + errore,
                      {
                        anchorOrigin: {
                          horizontal: "right",
                          vertical: "top",
                        },
                        variant: "error",
                      }
                    );
                    setSubmitting(false);
                    return;
                  }

                  enqueueSnackbar("Richiesta creata con successo", {
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top",
                    },
                    variant: "success",
                  });
                  setSubmitting(false);
                  navigate("/richieste");


                  return null;
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }): JSX.Element => (
                <form onSubmit={handleSubmit} {...props}>
                  <Box sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <Card>
                          <CardContent>
                            <CardHeader title="Dati cliente" />
                            <Box sx={{ mt: 2 }}>
                              <TextField
                                error={Boolean(touched.nome && errors.nome)}
                                fullWidth
                                helperText={touched.nome && errors.nome}
                                label="Nome"
                                name="nome"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.nome}
                                variant="outlined"
                              />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <TextField
                                error={Boolean(
                                  touched.cognome && errors.cognome
                                )}
                                fullWidth
                                helperText={touched.cognome && errors.cognome}
                                label="Cognome"
                                name="cognome"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.cognome}
                                variant="outlined"
                              />
                            </Box>

                            <Box sx={{ mt: 2 }}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={itLocale}
                              >
                                <DatePicker
                                  openTo="year"
                                  views={["year", "month", "date"]}
                                  onChange={(date: Date | null): void => {
                                    setFieldValue("dataNascita", date);
                                  }}
                                  value={values.dataNascita}
                                  renderInput={(props): JSX.Element => (
                                    <TextField
                                      {...props}
                                      error={Boolean(
                                        touched.dataNascita &&
                                        errors.dataNascita
                                      )}
                                      fullWidth
                                      helperText={
                                        touched.dataNascita &&
                                        errors.dataNascita
                                      }
                                      label="Data di nascita"
                                      name="dataNascita"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.dataNascita}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <Autocomplete
                                id="luogoNascita"
                                options={arrayLuoghi as any[]}
                                ListboxComponent={
                                  ListboxComponent as React.ComponentType<
                                    React.HTMLAttributes<HTMLElement>
                                  >
                                }
                                getOptionLabel={(option) =>
                                  option ? option.codice : ""
                                }
                                getOptionSelected={(option, value) =>
                                  option.codice === value.codice
                                }
                                renderGroup={renderGroup}
                                onChange={(e, value: any) => {
                                  setFieldValue(
                                    "luogoNascita",
                                    value !== null ? value : null
                                  );
                                  setFieldValue(
                                    "provinciaNascita",
                                    value !== null ? value : null
                                  );
                                }}
                                value={values.luogoNascita}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={Boolean(
                                      touched.luogoNascita &&
                                      errors.luogoNascita
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.luogoNascita &&
                                      errors.luogoNascita
                                    }
                                    label="Luogo di nascita"
                                    name="luogoNascita"
                                    onBlur={handleBlur}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <Autocomplete
                                id="provinciaNascita"
                                options={arrayLuoghi}
                                getOptionLabel={(option: any) =>
                                  option ? option.description : ""
                                }
                                getOptionSelected={(option, value) =>
                                  option?.description === value.description
                                }
                                ListboxComponent={
                                  ListboxComponent as React.ComponentType<
                                    React.HTMLAttributes<HTMLElement>
                                  >
                                }
                                renderGroup={renderGroup}
                                onChange={(e, value: any) => {
                                  setFieldValue(
                                    "provinciaNascita",
                                    value !== null ? value : null
                                  );
                                }}
                                value={values.provinciaNascita}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={Boolean(
                                      touched.provinciaNascita &&
                                      errors.provinciaNascita
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.provinciaNascita &&
                                      errors.provinciaNascita
                                    }
                                    label="Provincia nascita"
                                    name="provinciaNascita"
                                    onBlur={handleBlur}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Box>

                            <Box sx={{ mt: 2 }}>
                              <Autocomplete
                                id="sesso"
                                //name="sesso"
                                options={[
                                  { value: "M", title: "Uomo" },
                                  {
                                    value: "F",
                                    title: "Donna",
                                  },
                                ]}
                                getOptionLabel={(option) => option.title}
                                onChange={(e, value: any) => {
                                  //console.log(value);
                                  setFieldValue(
                                    "sesso",
                                    value !== null ? value.value : null
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={Boolean(
                                      touched.sesso && errors.sesso
                                    )}
                                    fullWidth
                                    helperText={touched.sesso && errors.sesso}
                                    label="Sesso"
                                    name="sesso"
                                    onBlur={handleBlur}
                                    //onChange={handleChange}
                                    value={values.sesso}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <TextField
                                error={Boolean(
                                  touched.codiceFiscale && errors.codiceFiscale
                                )}
                                fullWidth
                                helperText={
                                  touched.codiceFiscale && errors.codiceFiscale
                                }
                                label="Codice fiscale"
                                name="codiceFiscale"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.codiceFiscale}
                                variant="outlined"
                              />
                            </Box>

                            <Box sx={{ mt: 2 }}>
                              <TextField
                                error={Boolean(
                                  touched.telefono && errors.telefono
                                )}
                                fullWidth
                                helperText={touched.telefono && errors.telefono}
                                label="Telefono"
                                name="telefono"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.telefono}
                                variant="outlined"
                              />
                            </Box>

                            <Box sx={{ mt: 2 }}>
                              <TextField
                                error={Boolean(
                                  touched.cellulare && errors.cellulare
                                )}
                                fullWidth
                                helperText={
                                  touched.cellulare && errors.cellulare
                                }
                                label="Cellulare"
                                name="cellulare"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.cellulare}
                                variant="outlined"
                              />
                            </Box>

                            <Box sx={{ mt: 2 }}>
                              <TextField
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                helperText={touched.email && errors.email}
                                label="E-Mail"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                variant="outlined"
                              />
                            </Box>

                            <Box sx={{ mt: 2 }}>
                              <TextField
                                error={Boolean(touched.nota && errors.nota)}
                                fullWidth
                                helperText={touched.nota && errors.nota}
                                label="Nota"
                                name="nota"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.nota}
                                variant="outlined"
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Card>
                          <CardContent>
                            <Box sx={{ mt: 2 }}>
                              <CardHeader title="Informazioni richiesta" />

                              <TextField
                                error={Boolean(
                                  touched.importo && errors.importo
                                )}
                                type={"number"}
                                fullWidth
                                helperText={touched.importo && errors.importo}
                                label="Importo"
                                name="importo"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.importo}
                                variant="outlined"
                              />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <Autocomplete
                                id="tipoImpiego"
                                options={arrayTipoImpiego}
                                getOptionLabel={(option: any) =>
                                  option.description
                                }
                                onChange={(e, value) => {
                                  //console.log(value);
                                  setFieldValue(
                                    "tipoImpiego",
                                    value !== null ? value.codice : null
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={Boolean(
                                      touched.tipoImpiego && errors.tipoImpiego
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.tipoImpiego && errors.tipoImpiego
                                    }
                                    label="Tipo impiego"
                                    name="tipoImpiego"
                                    value={values.tipoImpiego}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={itLocale}
                              >
                                <DatePicker
                                  openTo="year"
                                  views={["year", "month", "date"]}
                                  onChange={(date: Date | null): void => {
                                    setFieldValue("dataAssunzione", date);
                                    console.log(date);
                                  }}
                                  value={values.dataAssunzione}
                                  renderInput={(props): JSX.Element => (
                                    <TextField
                                      {...props}
                                      error={Boolean(
                                        touched.dataAssunzione &&
                                        errors.dataAssunzione
                                      )}
                                      fullWidth
                                      helperText={
                                        touched.dataAssunzione &&
                                        errors.dataAssunzione
                                      }
                                      label="Data di assunzione"
                                      name="dataAssunzione"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.dataAssunzione}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Box>

                            <Box sx={{ mt: 3 }}>
                              <Tabs
                                indicatorColor="primary"
                                onChange={handleTabsChange}
                                scrollButtons="auto"
                                textColor="primary"
                                value={currentTab}
                                variant="scrollable"
                              >
                                {tabs.map((tab) => (
                                  <Tab
                                    key={tab.value}
                                    label={tab.label}
                                    value={tab.value}
                                  />
                                ))}
                              </Tabs>
                            </Box>
                            {currentTab === "elencoDocumenti" && (
                              <Box sx={{ p: 2 }}>
                                <Stepper
                                  activeStep={activeStep}
                                  orientation="vertical"
                                >
                                  {steps.map((label, index) => (
                                    <Step key={label}>
                                      <StepLabel>{label}</StepLabel>
                                      <StepContent>
                                        <Typography>
                                          {getStepContent(index)}
                                        </Typography>
                                        <div
                                          className={classes.actionsContainer}
                                        >
                                          <div>
                                            <Button
                                              disabled={activeStep === 0}
                                              onClick={handleBack}
                                              className={classes.button}
                                            >
                                              Indietro
                                            </Button>
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              onClick={handleNext}
                                              className={classes.button}
                                            >
                                              {activeStep === steps.length - 1
                                                ? "Fine"
                                                : "Prossimo"}
                                            </Button>
                                          </div>
                                        </div>
                                      </StepContent>
                                    </Step>
                                  ))}
                                </Stepper>
                                {activeStep === steps.length && (
                                  <Box sx={{ mt: 2 }}>
                                    <Typography>
                                      Tutti i documenti sono stati caricati
                                    </Typography>
                                    <Button
                                      onClick={handleReset}
                                      className={classes.button}
                                    >
                                      Ricomincia
                                    </Button>
                                  </Box>
                                )}
                              </Box>
                            )}

                            {currentTab === "documentoSingolo" && (
                              <Box sx={{ p: 2 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    p: 1,
                                  }}
                                >
                                  <FormLabel>Documenti anagrafici</FormLabel>
                                  <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                      <TextField
                                        type={"number"}
                                        label="Inizio pagina"
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          setInizioDocumentiAnagrafici(
                                            parseInt(e.target.value)
                                          )
                                        }
                                        value={inizioDocumentiAnagrafici}
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        type={"number"}
                                        label="Fine pagina"
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          setFineDocumentiAnagrafici(
                                            parseInt(e.target.value)
                                          )
                                        }
                                        value={fineDocumentiAnagrafici}
                                        variant="outlined"
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    p: 1,
                                  }}
                                >
                                  <FormLabel>Documenti reddituali</FormLabel>
                                  <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                      <TextField
                                        type={"number"}
                                        label="Inizio pagina"
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          setInizioDocumentiReddituali(
                                            parseInt(e.target.value)
                                          )
                                        }
                                        value={inizioDocumentiReddituali}
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        type={"number"}
                                        label="Fine pagina"
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          setFineDocumentiReddituali(
                                            parseInt(e.target.value)
                                          )
                                        }
                                        value={fineDocumentiReddituali}
                                        variant="outlined"
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    p: 1,
                                  }}
                                >
                                  <FormLabel>Modulistica precontrattuale</FormLabel>
                                  <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                      <TextField
                                        type={"number"}
                                        label="Inizio pagina"
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          setInizioPrivacySMC(
                                            parseInt(e.target.value)
                                          )
                                        }
                                        value={inizioPrivacySMC}
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        type={"number"}
                                        label="Fine pagina"
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          setFinePrivacySMC(
                                            parseInt(e.target.value)
                                          )
                                        }
                                        value={finePrivacySMC}
                                        variant="outlined"
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    p: 1,
                                  }}
                                >
                                  <FormLabel>Documenti integrativi</FormLabel>
                                  <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                      <TextField
                                        type={"number"}
                                        label="Inizio pagina"
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          setInizioDocumentiIntegrativi(
                                            parseInt(e.target.value)
                                          )
                                        }
                                        value={inizioDocumentiIntegrativi}
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        type={"number"}
                                        label="Fine pagina"
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          setFineDocumentiIntegrativi(
                                            parseInt(e.target.value)
                                          )
                                        }
                                        value={fineDocumentiIntegrativi}
                                        variant="outlined"
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                                <div>
                                  <FilePond
                                    files={fileSingolo}
                                    allowMultiple={false}
                                    onupdatefiles={setFileSingolo}
                                    allowFileTypeValidation
                                    fileValidateTypeLabelExpectedTypes="{allTypes}"
                                    name="Documento"
                                    acceptedFileTypes={["application/pdf"]}
                                    labelIdle='Trascina e lascia il pdf o <span class="filepond--label-action">Cerca</span>'
                                  //required={true}
                                  />
                                </div>
                              </Box>
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 3,
                      }}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={24} disableShrink />
                      ) : (
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                        >
                          Crea richiesta
                        </Button>
                      )}
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NuovaRichiesta;
