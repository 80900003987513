import type { FC } from "react";
import { useEffect, useState, ChangeEvent } from "react";
import useSettings from "../../hooks/useSettings";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  Tabs,
  Tab,
  Tooltip,
  StepLabel,
  Step,
  Stepper,
  Button,
  CircularProgress,
  LinearProgress,
} from "@material-ui/core";
import ChevronRightIcon from "../../icons/ChevronRight";
import DettaglioRichiesta from "./components/dettaglioRichiesta";
import {
  SingolaPratica,
  Richiesta,
  RispostaSingolaRichiesta,
} from "src/types/generated";
import GraphqlClient from "src/client/graphql.client";
import DettaglioPratica from "./components/dettaglioPratica";
import { useSelector as useReduxSelector } from "../../store";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import AggiungiDocumento from "./components/aggiungiDocumento";
import RefreshIcon from "@material-ui/icons/Refresh";

const tabs = [
  { label: "Richiesta", value: "richiesta" },
  { label: "Documenti", value: "aggiungiDocumento" },
];

const Dettaglio: FC = (props) => {
  const { settings } = useSettings();
  const [currentTab, setCurrentTab] = useState<string>("richiesta");
  const configuration = useReduxSelector(
    (state) => state.configuration.itemsMap
  );

  const { ...other } = props;
  const params = useParams();

  const id = Number(params.id);
  const statoRichiesta = Number(params.statoRichiesta);
  const statoPratica = Number(params.statoPratica);

  const [loadingData, setLoadingData] = useState(false);

  const [richiesta, setRichiesta] = useState({} as RispostaSingolaRichiesta);
  const [pratica, setPratica] = useState({} as SingolaPratica);

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const caricaRichiesta = async () => {
    try {
      setLoadingData(true);
      const res = await GraphqlClient.datiSingolaRichiesta({ richiestaId: id });
      setRichiesta(res.datiSingolaRichiesta);

      await caricaPratica(res.datiSingolaRichiesta.richiesta.praticaCaricataId);
    } catch (e) {
      console.error("Impossibile ottenere dati richiesta: ", e);
    } finally {
      setLoadingData(false);
    }
  };

  const caricaPratica = async (praticaId: string) => {
    try {
      setLoadingData(true);
      const res = await GraphqlClient.datiSingolaPratica({ id: praticaId });
      setPratica(res.datiSingolaPratica);
      if (tabs.length > 2) {
        tabs.splice(1, 1);
      }
      tabs.splice(1, 0, { label: "Pratica", value: "pratica" });
      //console.log(tabs)
    } catch (e) {
      console.error("Impossibile ottenere dati pratica: ", e);
      tabs.map((elem, index) => {
        if (elem.label == "Pratica") tabs.splice(index, 1);
      });
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    caricaRichiesta();
  }, []);

  useEffect(() => {
    console.log("pratica -> ", pratica?.macroStato);
    console.log("richiesta -> ", richiesta?.richiesta?.macroStato);
  }, [pratica, richiesta]);

  return (
    <>
      <Helmet>
        <title>Dettaglio Pratica</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Typography color="textPrimary" variant="h5">
            Dettaglio Pratica: {richiesta?.richiesta?.codice}
          </Typography>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/richieste"
                  variant="subtitle2"
                >
                  Richieste
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Pratica
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                width: "20%",
              }}
              item
            >
              <Box>
                <Button
                  variant="outlined"
                  startIcon={<RefreshIcon fontSize="small" />}
                  onClick={() => caricaRichiesta()}
                >
                  Refresh
                </Button>
              </Box>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<ArrowLeftIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                  to="/richieste"
                  variant="outlined"
                >
                  Indietro
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ m: 2 }}>
                <Stepper activeStep={richiesta?.richiesta?.macroStato - 1}>
                  {Object.keys(configuration["macrostatoRichiesta"])?.map(
                    (key) => {
                      let testo = configuration["macrostatoRichiesta"][key];
                      if (testo.length === 0) return null;

                      return (
                        <Step>
                          <StepLabel>{testo}</StepLabel>
                        </Step>
                      );
                    }
                  )}
                </Stepper>
              </Box>
              <Box sx={{ m: 2 }}>
                <Stepper
                  connector={null}
                  activeStep={
                    pratica?.macroStato != undefined ? pratica?.macroStato : -1
                  }
                >
                  {Object.keys(configuration["macrostatoPratica"])?.map(
                    (key) => {
                      let testo = configuration["macrostatoPratica"][key];
                      if (testo.length === 0) return null;

                      return (
                        <Step>
                          <StepLabel>{testo}</StepLabel>
                        </Step>
                      );
                    }
                  )}
                </Stepper>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Divider />
          {
            loadingData && <LinearProgress />
          }
          <Box sx={{ mt: 3 }}>
            {currentTab === "richiesta" && (
              <DettaglioRichiesta
                richiesta={richiesta}
                stato={statoRichiesta}
              />
            )}
            {currentTab === "pratica" && <DettaglioPratica pratica={pratica} />}
            {currentTab === "aggiungiDocumento" && (
              <AggiungiDocumento richiesta={richiesta} />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Dettaglio;
