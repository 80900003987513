import store from "../../store";

import { actions } from './redux.richiesteAnonime';
import GraphqlClient from "../../client/graphql.client";
import { FiltersRic, InputCompletaPreventivoAnonimo, InputRichiestaPreventiviAnonimi } from "src/types/generated";

export const loadRichiesteAnonime = async (filters: FiltersRic) => {
    try {
        const res = await GraphqlClient.ottieniRichiesteAnonime({ filters });
        store.dispatch(actions.load(res.ottieniRichiesteAnonime.richiesteList))
        return { data: res.ottieniRichiesteAnonime };
    } catch (error) {
        console.error(error);
        return { loaded: false, message: error.toString() }
    }
}

export const creaRichiestaAnonima = async (input: InputRichiestaPreventiviAnonimi) => {
    try {
        const res = await GraphqlClient.richiediPreventiviAnonimi({ input });
        return { data: res.richiediPreventiviAnonimi };
    } catch (error) {
        console.error(error);
        return { error: true, message: error.response.errors }
    }
}

export const completaRichiestaAnonima = async (richiestaId: number, input: InputCompletaPreventivoAnonimo) => {
    try {
        const res = await GraphqlClient.completaPreventivoAnonimo({ richiestaId, input });
        return { data: res.completaPreventivoAnonimo };
    } catch (error) {
        console.error(error);
        return { error: true, message: error.response.errors }
    }
}