import {combineReducers} from '@reduxjs/toolkit';
import authentication from '../pages/login/redux.login';
import configuration from '../pages/login/redux.configurazione';
import storage from 'redux-persist/lib/storage'
import persistReducer from "redux-persist/es/persistReducer";
import users from '../pages/users/redux.user'
import roles from '../pages/roles/redux.role'
import richieste from '../pages/richieste/redux.richieste'
import dashboardGenerale from '../pages/dashboard/redux.dashboard'
import dashboardCommissionale from '../pages/dashboardCommissionale/redux.dashboardCommissionale'
import note from '../pages/note/redux.note'
import richiesteAnonime from '../pages/richiesteAnonime/redux.richiesteAnonime'
// YEOMAN ANCHOR IMPORT */
import report from "../pages/report/redux.report"


// Aggiungere reducers qui
const rootReducer = combineReducers({
    auth: authentication,
    users,
    roles,
    richieste: richieste,
    dashboardGenerale: dashboardGenerale,
    dashboardCommissionale: dashboardCommissionale,
    note: note,
    configuration: configuration,
    richiesteAnonime,
    // YEOMAN ANCHOR REDUCER */
report,

});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ["auth"],
    debug: true
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export default persistedReducer;
