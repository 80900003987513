import React, { FC, useEffect, useState } from 'react'
import {
    Box,
    Grid,
    Card,
    Typography,
    /* Table_MUI */
    TableHead,
    TableRow,
    TableCell,
    Divider,
    CardContent,
    TableBody,
    TableFooter,
    Skeleton,
    CardHeader, Chip, Tooltip, IconButton, Stepper, Step, StepLabel, Button, CircularProgress
} from '@material-ui/core'
import Scrollbar from "src/material/Scrollbar";
import AddIcon from '@material-ui/icons/Add';
import { Link as RouterLink } from 'react-router-dom';
import GraphqlClient from 'src/client/graphql.client'
import { useSnackbar } from 'notistack'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
//import dayjs from 'dayjs';
import { useParams } from 'react-router-dom'
import {
    Table,
    SelectColumnFilter,
    DatePickerRangeFilter,
    DatePickerRangeFilterMethod,
    SelectColumnFilterMethod
} from '../../../components/Table/Table'
import { AccettaPreventivo, Allegati, Entity, Note, RispostaSingolaRichiesta } from 'src/types/generated'
import moment from 'moment';
import { useSelector as useReduxSelector } from "../../../store";
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import _ from "lodash";

interface DettaglioRichiestaProps {
    richiesta: RispostaSingolaRichiesta;
    stato: any;
}


const DettaglioRichiesta: FC<DettaglioRichiestaProps> = ({ richiesta, stato }: DettaglioRichiestaProps) => {

    const configuration = useReduxSelector(state => state.configuration.itemsMap)
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = React.useState(false);
    const [openNuovaNotaDialog, setOpenNuovaNotaDialog] = React.useState(false);
    const [notaAllegata, setNotaAllegata] = useState("")
    const [nuovaNota, setNuovaNota] = useState("")

    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingId, setLoadingId] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
        setNotaAllegata("")
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenNuovaNota = () => {
        setOpenNuovaNotaDialog(true);
        setNuovaNota("")
    };

    const handleCloseNuovaNotaDialog = () => {
        setOpenNuovaNotaDialog(false);
    };

    const scaricaPrecontrattuale = async (preventivo) => {
        let oggJson = JSON.stringify({ preventivo, richiesta });
        try {
            setLoadingDownload(true);
            setLoadingId(preventivo.id);
            const res = await GraphqlClient.downloadPrecontrattuale({ dati: oggJson });
            const linkSource = `data:application/pdf;base64,${res.downloadPrecontrattuale}`;
            const downloadLink = document.createElement("a");
            const fileName = "precontrattuale" + ".pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        } catch (e) {
            alert("Impossibile scaricare precontrattuale");
            console.error(e);
        } finally {
            setLoadingDownload(false);
            setLoadingId("");
        }
    };

    const scaricaDocumento = async (id) => {
        console.log(id)
        try {
            const res = await GraphqlClient.downloadDocumento({ documentoId: id });
            let base64 = res.downloadDocumento.document.base64;
            let tipoFile = res.downloadDocumento.document.fileType;
            let docID = res.downloadDocumento.document.documentoId;

            const bytes = atob(base64);
            let length = bytes.length;
            let out = new Uint8Array(length);
            while (length--) {
                out[length] = bytes.charCodeAt(length);
            }

            let myBlob = new Blob([out], { type: "application/" + tipoFile });

            const URLblobObj = window.URL.createObjectURL(myBlob);

            let a = document.createElement("a");
            a.style.display = "none";
            a.href = URLblobObj;
            a.download = docID + ".pdf";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(URLblobObj);
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Impossibile scaricare il documento", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "error",
            });
        }
    };

    const richiediUlteriorePreventivo = async () => {
        setOpen(false);
        //console.log(notaAllegata)
        try {
            await GraphqlClient.chiediUlteriorePreventivo({
                richiestaId: Number(richiesta.richiesta.id),
                nota: notaAllegata
            })
            enqueueSnackbar("Nuovo preventivo richiesto", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });

        } catch (e) {
            console.error(e)
            enqueueSnackbar("Impossibile richiedere nuovo preventivo", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
    }

    const checkIfEmpty = () => {
        let check = false;
        if (nuovaNota === "") {
            check = true
        }
        if (check)
            return false
        return true
    }

    const inserisciNota = async () => {
        if (!checkIfEmpty()) {
            enqueueSnackbar("Non è possibile inserire note vuote", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            return;
        }
        try {
            let elencoNoteDaInserire: Note[] = [];
            let singolaNota: Note = {
                Nota: nuovaNota,
                TipoNota: 30
            }
            elencoNoteDaInserire.push(singolaNota)
            //console.log("queste sono le note da inserire-> ", entity)

            let Entity: Entity = {
                Tipo: 'richiestaPreventivi',
                Id: String(richiesta.richiesta.id)
            };


            await GraphqlClient.createNota({
                entity: Entity,
                notes: elencoNoteDaInserire
            });

            enqueueSnackbar("Nota inserita", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });

        } catch (e) {
            console.error(e);
            enqueueSnackbar("Impossibile inserire la nota", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        setOpenNuovaNotaDialog(false)
    }

    let isDisabled = true;
    if (richiesta.richiesta?.stato === 3 || richiesta.richiesta?.stato === 6) {
        isDisabled = false;
    }

    return (
        <>

            <Grid
                container
                spacing={3}
            >

                <Grid item xs={3}>
                    <Card>
                        <CardHeader
                            title="Anagrafica cliente"
                        />
                        <Divider />
                        <CardContent>
                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Cliente
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {richiesta.cliente?.cognome + " " + richiesta.cliente?.nome}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Data Nascita
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {moment(richiesta.cliente?.DataNascita).format("DD-MM-YYYY")}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Luogo Nascita
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {richiesta.cliente?.cittaNascita}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Sesso
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {richiesta.cliente?.sesso}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Codice Fiscale
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {richiesta.cliente?.codiceFiscale}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Telefono
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {richiesta.richiesta?.telefono}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Cellulare
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {richiesta.richiesta?.cellulare}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Email
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {richiesta.richiesta?.eMail}
                            </Typography>

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={9}>
                    <Card>
                        <CardHeader
                            title="Dati richiesta"
                        />
                        <Divider />
                        <CardContent>
                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Numero
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {/* richiesta.cliente.nome + richiesta.cliente.cognome  */}
                                {richiesta.richiesta?.codice}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Importo Richiesto
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {richiesta.richiesta?.importoRichiesto}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Tipo impiego
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {richiesta.richiesta?.tipoImpiego && configuration["tipoImpiego"] ? configuration["tipoImpiego"][richiesta.richiesta?.tipoImpiego] : richiesta.richiesta?.tipoImpiego}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Data assunzione
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {moment(richiesta.richiesta?.dataAssunzione).format("DD-MM-YYYY")}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <Box m={1}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between"
                            }}
                        >
                            <CardHeader
                                title="Preventivi Disponibili"
                            />
                            <Button
                                variant="outlined"
                                startIcon={<AddIcon fontSize="small" />}
                                onClick={() => handleClickOpen()}
                                disabled={isDisabled}
                            >
                                Ulteriore Preventivo
                            </Button>
                        </Box>
                        <div>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="form-dialog-title"
                                fullWidth={true}
                                maxWidth={'md'}
                            >
                                <DialogTitle id="titleNota1">Aggiungi nota</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Allega una nota alla ulteriore richiesta di preventivo
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="nota"
                                        label="Nota"
                                        type="text"
                                        fullWidth
                                        value={notaAllegata}
                                        onChange={
                                            (e) => {
                                                setNotaAllegata(e.target.value)
                                            }
                                        }
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Annulla
                                    </Button>
                                    <Button onClick={richiediUlteriorePreventivo} color="primary">
                                        Invia
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                        <Scrollbar>
                            <Box>
                                <Table
                                    disableFilters={true}
                                    disableSorting={true}
                                    Data={richiesta.listPreventivi?.sort((a, b) => a.codice > b.codice ? -1 : 1).map((elem, index, arr) => {
                                        if (index > 2) {
                                            return { ...elem, background: 'rgba(121, 134, 203, 0.1)' }
                                        }
                                        return elem
                                    }) || []}
                                    Columns={
                                        [
                                            {
                                                accessor: 'codice',
                                                Header: 'Codice',
                                                width: 100,
                                            },
                                            {
                                                accessor: 'descrizioneBancaErogante',
                                                Header: 'Banca',
                                                width: 100
                                            },
                                            {
                                                accessor: 'tipoProdotto',

                                                Header: 'Tipo prodotto',
                                                width: 100,
                                                Cell: (row) => {
                                                    return <Chip
                                                        label={configuration["tipoProdotto"] ? configuration["tipoProdotto"][row.row.original.tipoProdotto] : row.row.original.tipoProdotto}
                                                        style={{ backgroundColor: '#607d8b' }} />
                                                }
                                            },
                                            {
                                                accessor: 'descrizioneProdotto',
                                                Header: 'Prodotto',
                                                width: 100
                                            },
                                            {
                                                accessor: 'rata',
                                                Header: 'Importo rata',
                                                width: 100
                                            },
                                            {
                                                accessor: 'durata',
                                                Header: 'Numero rate',
                                                width: 100
                                            },
                                            {
                                                accessor: 'montanteLordo',
                                                Header: 'Montante lordo',
                                                width: 100
                                            },
                                            {
                                                accessor: 'nettoErogato',
                                                Header: 'Netto erogato',
                                                width: 100
                                            },
                                            {
                                                accessor: 'praticaCaricataId',
                                                Header: 'Pratica legata',
                                                width: 100,
                                                Cell: () => richiesta.richiesta.praticaCaricataId
                                            },
                                            {
                                                accessor: 'dataSalvataggio',
                                                Header: 'Data salvataggio',
                                                width: 100,
                                                Cell: () => { return moment(new Date(richiesta.richiesta.dataUltimaModifica)).format("DD MMM YYYY") }

                                            },
                                            {
                                                Header: "Accettato",
                                                width: 100,
                                                Cell: (row) => {
                                                    if (richiesta.richiesta.preventivoAccettatoId === row.row.original.id) {
                                                        return (
                                                            <Chip
                                                                style={{ backgroundColor: '#33cc33' }}
                                                                label="SI"

                                                            />
                                                        )
                                                    } else {
                                                        return (
                                                            <Chip
                                                                style={{ backgroundColor: '#607d8b' }}
                                                                label="NO"

                                                            />
                                                        )
                                                    }
                                                }
                                            },
                                            {
                                                Header: 'Azioni',
                                                width: 100,
                                                Cell: (row) => {
                                                    if (!richiesta.richiesta.praticaCaricataId) {
                                                        if (richiesta.richiesta.stato === 3 || richiesta.richiesta.stato === 6) {
                                                            return (
                                                                <>
                                                                    {/*TODO: Far vedere scarica pre contrattuale se pratica legata non è vuota*/}
                                                                    {
                                                                        loadingDownload && loadingId === row.row.original.id ?
                                                                            <CircularProgress size={24} disableShrink />
                                                                            :
                                                                            <Tooltip title={"Scarica stampa"}>
                                                                                <IconButton
                                                                                    disabled={row.row.original.listDocumenti.length == 0 || row.row.original.listDocumenti.find(elem => elem.descrizione === 'STAMPA PREVENTIVO') === undefined}
                                                                                    aria-label='stampa'
                                                                                    color="primary"
                                                                                    onClick={() => scaricaDocumento(row.row.original.listDocumenti.find(elem => elem.descrizione === 'STAMPA PREVENTIVO').documentoId)}
                                                                                >
                                                                                    <PictureAsPdfIcon fontSize="small" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                    }

                                                                    {
                                                                        loadingDownload && loadingId === row.row.original.id ?
                                                                            <CircularProgress size={24} disableShrink />
                                                                            :
                                                                            <Tooltip title={"Scarica precontrattuale"}>
                                                                                <IconButton
                                                                                    aria-label='Download'
                                                                                    color="primary"
                                                                                    onClick={() => scaricaPrecontrattuale(row.row.original)}
                                                                                >
                                                                                    <CloudDownloadIcon fontSize="small" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                    }

                                                                    {/*TODO: Se c'è almeno ( in tutti i preventivi ) una pratica legata, disabilitare il pulsante approva preventivo*/}
                                                                    <Tooltip title={"Approva preventivo"}>
                                                                        <IconButton
                                                                            aria-label='Elimina'
                                                                            color="primary"
                                                                            component={RouterLink}
                                                                            to={`/richieste/accettaPreventivo/${richiesta.richiesta.id}/${row.row.original.id}/${stato}`}
                                                                        >
                                                                            <LibraryAddCheckIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                </>
                                                            )
                                                        } else {
                                                            return (
                                                                <>
                                                                    {
                                                                        loadingDownload && loadingId === row.row.original.id ?
                                                                            <CircularProgress size={24} disableShrink />
                                                                            :
                                                                            <Tooltip title={"Scarica stampa"}>
                                                                                <IconButton
                                                                                    disabled={row.row.original.listDocumenti.length == 0 || row.row.original.listDocumenti.find(elem => elem.descrizione === 'STAMPA PREVENTIVO') === undefined}
                                                                                    aria-label='stampa'
                                                                                    color="primary"
                                                                                    onClick={() => scaricaDocumento(row.row.original.listDocumenti.find(elem => elem.descrizione === 'STAMPA PREVENTIVO').documentoId)}
                                                                                >
                                                                                    <PictureAsPdfIcon fontSize="small" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                    }

                                                                    {
                                                                        loadingDownload ?
                                                                            <CircularProgress size={24} disableShrink />
                                                                            :
                                                                            <Tooltip title={"Scarica precontrattuale"}>
                                                                                <IconButton
                                                                                    aria-label='Download'
                                                                                    color="primary"
                                                                                    onClick={() => scaricaPrecontrattuale(row.row.original)}
                                                                                >
                                                                                    <CloudDownloadIcon fontSize="small" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                    }
                                                                </>
                                                            )
                                                        }

                                                    } else {
                                                        return ''
                                                    }

                                                }
                                            },
                                        ]
                                    }
                                />

                            </Box>
                        </Scrollbar>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <Dialog
                            open={openNuovaNotaDialog}
                            onClose={handleCloseNuovaNotaDialog}
                            aria-labelledby="form-dialog-title"
                            fullWidth={true}
                            maxWidth={'md'}
                        >
                            <DialogTitle id="titleNota2">Aggiungi nota</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Inserisci il contenuto della nota
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="normal"
                                    id="nota"
                                    label="Nota"
                                    type="text"
                                    fullWidth
                                    value={nuovaNota}
                                    onChange={
                                        (e) => {
                                            setNuovaNota(e.target.value)
                                        }
                                    }
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseNuovaNotaDialog} color="primary">
                                    Annulla
                                </Button>
                                <Button onClick={inserisciNota} color="primary">
                                    Invia
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <Card>
                        <CardHeader
                            title="Lista Note"
                            action={
                                <IconButton onClick={handleClickOpenNuovaNota}>
                                    <AddCircleIcon color="primary" fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Scrollbar>
                            <Table
                                disableFilters={true}
                                disableSorting={true}
                                Data={richiesta.listNote || []}
                                Columns={
                                    [
                                        {
                                            Header: 'Tipo',
                                            accessor: 'TipoNota',
                                            width: 100,
                                            Cell: (row) => {
                                                return <Chip
                                                    label={row.row.original.TipoNota === 30 ? "RICHIESTA" : "error"}
                                                    style={{ backgroundColor: '#2196f3\n' }} />
                                            }
                                        },
                                        {
                                            Header: 'Data inserimento',
                                            accessor: 'DataInserimento',
                                            Cell: (row) => {
                                                return moment(new Date(row.row.original.DataInserimento)).format("DD MMM YYYY | HH:mm")
                                            },
                                            width: 120
                                        },
                                        {
                                            Header: 'Mittente',
                                            accessor: 'UtenteCognome',
                                            Cell: (row) => {

                                                let str = row.row.original.UtenteCognome + " " + row.row.original.UtenteNome;
                                                let splitStr = str.toLowerCase().split(' ');
                                                for (let i = 0; i < splitStr.length; i++) {
                                                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                                                }
                                                return splitStr.join(' ')
                                            },
                                            width: 150
                                        },
                                        {
                                            Header: 'Testo',
                                            accessor: 'Nota',
                                            width: 300,
                                            Cell: (row) => {
                                                if (row.value.length >= 80) {
                                                    return row.value.substring(0, 80) + '...'
                                                } else {
                                                    return row.value
                                                }
                                            }
                                        }
                                    ]
                                }
                            />
                        </Scrollbar>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default DettaglioRichiesta
