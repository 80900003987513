import React, { useEffect, useState } from "react";
import type { FC } from "react";
import {
    Link as RouterLink,
    useNavigate,
    useLocation,
    useParams,
} from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    Card,
    FormLabel,
    CardContent,
    Tabs,
    Tab,
    CardHeader,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    FormHelperText,
    Grid,
    Link,
    Select,
    TextField,
    Typography,
    AutocompleteRenderGroupParams,
} from "@material-ui/core";
import useSettings from "../../../hooks/useSettings";
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../../icons/ChevronRight";
import ArrowLeftIcon from "../../../icons/ArrowLeft";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { FilePondErrorDescription, FilePondFile } from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import { useSelector as useReduxSelector } from "../../../store";

import {
    makeStyles,
    Theme,
    createStyles,
    useTheme,
} from "@material-ui/core/styles";

import JSZip, { folder } from "jszip";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { FileConIntero, InputCompletaPreventivoAnonimo } from "src/types/generated";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import itLocale from "date-fns/locale/it";
import { completaRichiestaAnonima } from "../commands.richiestaAnonima";

// filepond plugin
registerPlugin(FilePondPluginFileValidateType);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
    })
);

const CompletaRichiesta = ({ richiestaId }: { richiestaId: number }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { settings } = useSettings();
    const { enqueueSnackbar } = useSnackbar();

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    const configuration = useReduxSelector((state) => state.configuration);

    const handleNext = () => {
        if (fileDocReddituali.length === 0 && activeStep === 1) {
            enqueueSnackbar("Hai dimenticato di inserire documenti obbligatori", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "error",
            });
            return;
        }
        if (filePrivacySMC.length === 0 && activeStep === 2) {
            enqueueSnackbar("Hai dimenticato di inserire documenti obbligatori", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "error",
            });
            return;
        }
        if (fileDocAnagrafici.length === 0 && activeStep === 0) {
            enqueueSnackbar("Hai dimenticato di inserire documenti obbligatori", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "error",
            });
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [fileDocReddituali, setFileDocReddituali] = useState([]);
    const [filePrivacySMC, setFilePrivacySMC] = useState([]);
    const [fileDocAnagrafici, setFileDocAnagrafici] = useState([]);
    const [fileDocIntegrativi, setFileDocIntegrativi] = useState([]);

    const [filesInfo, setFilesInfo] = useState([]);
    const [B64zip, setB64zip] = useState("");

    const creaZip = async () => {
        // creo lo zip di con tutti i files
        let files = fileDocReddituali.concat(
            filePrivacySMC,
            fileDocAnagrafici,
            fileDocIntegrativi
        );
        var zip = new JSZip();
        for (let i = 0; i < files.length; i++) {
            zip.file(files[i].file.name, files[i].file);
        }

        let listInfoFiles: FileConIntero[] = [];
        fileDocAnagrafici.map((elem) => {
            let fileInfo = {
                nomeFile: elem.file.name,
                tipoDocumento: 350,
                fileType: "pdf",
            };
            listInfoFiles.push(fileInfo);
        });

        fileDocIntegrativi.map((elem) => {
            let fileInfo = {
                nomeFile: elem.file.name,
                tipoDocumento: 352,
                fileType: "pdf",
            };
            listInfoFiles.push(fileInfo);
        });
        fileDocReddituali.map((elem) => {
            let fileInfo = {
                nomeFile: elem.file.name,
                tipoDocumento: 351,
                fileType: "pdf",
            };
            listInfoFiles.push(fileInfo);
        });
        filePrivacySMC.map((elem) => {
            let fileInfo = {
                nomeFile: elem.file.name,
                tipoDocumento: 353,
                fileType: "pdf",
            };
            listInfoFiles.push(fileInfo);
        });

        setFilesInfo(listInfoFiles);
        const content = await zip.generateAsync({ type: "base64" });

        setB64zip(content);
        return { zip: content, listInfoFiles: listInfoFiles };
    };

    function getSteps() {
        return [
            "Documenti anagrafici *",
            "Documenti reddituali *",
            "Modulistica precontrattuale *",
            "Documenti integrativi",
        ];
    }

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <div>
                        <FilePond
                            files={fileDocAnagrafici}
                            allowMultiple={false}
                            onupdatefiles={setFileDocAnagrafici}
                            allowFileTypeValidation
                            fileValidateTypeLabelExpectedTypes="{allTypes}"
                            name="docAnagrafici"
                            acceptedFileTypes={["application/pdf"]}
                            labelIdle='Trascina e lascia il pdf o <span class="filepond--label-action">Cerca</span>'
                        />
                    </div>
                );
            case 1:
                return (
                    <div>
                        <FilePond
                            files={fileDocReddituali}
                            allowMultiple={false}
                            onupdatefiles={setFileDocReddituali}
                            allowFileTypeValidation
                            fileValidateTypeLabelExpectedTypes="{allTypes}"
                            name="docReddituali"
                            acceptedFileTypes={["application/pdf"]}
                            labelIdle='Trascina e lascia il pdf o <span class="filepond--label-action">Cerca</span>'
                        //required={true}
                        />
                    </div>
                );
            case 2:
                return (
                    <div>
                        <FilePond
                            files={filePrivacySMC}
                            allowMultiple={false}
                            onupdatefiles={setFilePrivacySMC}
                            allowFileTypeValidation
                            fileValidateTypeLabelExpectedTypes="{allTypes}"
                            name="privacySMC"
                            acceptedFileTypes={["application/pdf"]}
                            labelIdle='Trascina e lascia il pdf o <span class="filepond--label-action">Cerca</span>'
                        />
                    </div>
                );
            case 3:
                return (
                    <div>
                        <FilePond
                            files={fileDocIntegrativi}
                            allowMultiple={false}
                            onupdatefiles={setFileDocIntegrativi}
                            allowFileTypeValidation
                            fileValidateTypeLabelExpectedTypes="{allTypes}"
                            name="docIntegrativi"
                            acceptedFileTypes={["application/pdf"]}
                            labelIdle='Trascina e lascia il pdf o <span class="filepond--label-action">Cerca</span>'
                        />
                    </div>
                );
            default:
                return "Unknown step";
        }
    }

    const classes = useStyles();

    const arrayLuoghi =
        (configuration?.itemsForSelects &&
            configuration?.itemsForSelects["comuni"]) ||
        [];
    const arrayLuoghiCodice =
        (configuration?.itemsForSelects &&
            configuration?.itemsForSelects["comuni"].map((item) => item.codice)) ||
        [];

    return (
        <Box sx={{ mt: 3 }}>
            <Formik
                initialValues={{
                    nome: "",
                    cognome: "",
                    luogoNascita: null,
                    provinciaNascita: null,
                    codiceFiscale: "",
                    telefono: "",
                    cellulare: "",
                    email: "",
                    submit: null,
                }}
                onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                ): Promise<void> => {
                    //console.log("avviato submit")
                    setSubmitting(true);

                    const content = await creaZip();

                    let mbSize = (content.zip.length - 1) / Math.pow(1024, 2);
                    if (mbSize > 30) {
                        enqueueSnackbar(
                            `Allegati superiori a 30 MB: (${mbSize.toFixed(
                                1
                            )}MB)  impossibile procedere.`,
                            {
                                anchorOrigin: {
                                    horizontal: "right",
                                    vertical: "top",
                                },
                                variant: "error",
                            }
                        );
                        return;
                    }

                    let inputForm: InputCompletaPreventivoAnonimo = {
                        cognome: values.cognome,
                        nome: values.nome,
                        codiceFiscale: values.codiceFiscale,
                        cittaNascita: values.luogoNascita.codice,
                        provinciaNascita: values.provinciaNascita.description,
                        telefono: values.telefono,
                        cellulare: values.cellulare,
                        email: values.email,
                        allegati: {
                            base64zip: content.zip,
                            files: content.listInfoFiles,
                        },
                    };

                    const res = await completaRichiestaAnonima(richiestaId, inputForm);

                    if (res.error) {
                        console.log("ERRORE", res.message);

                        let errore = "Errore generico";
                        try {
                            if (res.message.length > 0) {
                                let err = JSON.parse(res.message[0].message).status;
                                errore = `(${err.status})[${err.errorMessage}]`;
                            }
                        } catch (e1) {
                            console.error("E!!!", e1);
                        }

                        enqueueSnackbar(
                            "Impossibile creare la richiesta: " + errore,
                            {
                                anchorOrigin: {
                                    horizontal: "right",
                                    vertical: "top",
                                },
                                variant: "error",
                            }
                        );
                        setSubmitting(false);
                        return;
                    }

                    enqueueSnackbar("Richiesta creata con successo", {
                        anchorOrigin: {
                            horizontal: "right",
                            vertical: "top",
                        },
                        variant: "success",
                    });
                    setSubmitting(false);
                    navigate("/richieste");
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    touched,
                    values,
                }): JSX.Element => (
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ p: 3 }}>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Card>
                                        <CardContent>
                                            <CardHeader title="Dati cliente" />
                                            <Box sx={{ mt: 2 }}>
                                                <TextField
                                                    error={Boolean(touched.nome && errors.nome)}
                                                    fullWidth
                                                    helperText={touched.nome && errors.nome}
                                                    label="Nome"
                                                    name="nome"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.nome}
                                                    variant="outlined"
                                                />
                                            </Box>
                                            <Box sx={{ mt: 2 }}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.cognome && errors.cognome
                                                    )}
                                                    fullWidth
                                                    helperText={touched.cognome && errors.cognome}
                                                    label="Cognome"
                                                    name="cognome"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.cognome}
                                                    variant="outlined"
                                                />
                                            </Box>

                                            <Box sx={{ mt: 2 }}>
                                                <Autocomplete
                                                    id="luogoNascita"
                                                    options={arrayLuoghi as any[]}
                                                    getOptionLabel={(option) =>
                                                        option ? option.codice : ""
                                                    }
                                                    getOptionSelected={(option, value) =>
                                                        option.codice === value.codice
                                                    }
                                                    onChange={(e, value: any) => {
                                                        setFieldValue(
                                                            "luogoNascita",
                                                            value !== null ? value : null
                                                        );
                                                        setFieldValue(
                                                            "provinciaNascita",
                                                            value !== null ? value : null
                                                        );
                                                    }}
                                                    value={values.luogoNascita}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={Boolean(
                                                                touched.luogoNascita &&
                                                                errors.luogoNascita
                                                            )}
                                                            fullWidth
                                                            helperText={
                                                                touched.luogoNascita &&
                                                                errors.luogoNascita
                                                            }
                                                            label="Luogo di nascita"
                                                            name="luogoNascita"
                                                            onBlur={handleBlur}
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Box>
                                            <Box sx={{ mt: 2 }}>
                                                <Autocomplete
                                                    id="provinciaNascita"
                                                    options={arrayLuoghi}
                                                    getOptionLabel={(option: any) =>
                                                        option ? option.description : ""
                                                    }
                                                    getOptionSelected={(option, value) =>
                                                        option?.description === value.description
                                                    }
                                                    onChange={(e, value: any) => {
                                                        setFieldValue(
                                                            "provinciaNascita",
                                                            value !== null ? value : null
                                                        );
                                                    }}
                                                    value={values.provinciaNascita}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={Boolean(
                                                                touched.provinciaNascita &&
                                                                errors.provinciaNascita
                                                            )}
                                                            fullWidth
                                                            helperText={
                                                                touched.provinciaNascita &&
                                                                errors.provinciaNascita
                                                            }
                                                            label="Provincia nascita"
                                                            name="provinciaNascita"
                                                            onBlur={handleBlur}
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Box>
                                            <Box sx={{ mt: 2 }}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.codiceFiscale && errors.codiceFiscale
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.codiceFiscale && errors.codiceFiscale
                                                    }
                                                    label="Codice fiscale"
                                                    name="codiceFiscale"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.codiceFiscale}
                                                    variant="outlined"
                                                />
                                            </Box>

                                            <Box sx={{ mt: 2 }}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.telefono && errors.telefono
                                                    )}
                                                    fullWidth
                                                    helperText={touched.telefono && errors.telefono}
                                                    label="Telefono"
                                                    name="telefono"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.telefono}
                                                    variant="outlined"
                                                />
                                            </Box>

                                            <Box sx={{ mt: 2 }}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.cellulare && errors.cellulare
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.cellulare && errors.cellulare
                                                    }
                                                    label="Cellulare"
                                                    name="cellulare"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.cellulare}
                                                    variant="outlined"
                                                />
                                            </Box>

                                            <Box sx={{ mt: 2 }}>
                                                <TextField
                                                    error={Boolean(touched.email && errors.email)}
                                                    fullWidth
                                                    helperText={touched.email && errors.email}
                                                    label="E-Mail"
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Card>
                                        <CardContent>
                                            <CardHeader title="Documenti richiesta" />

                                            <Box sx={{ p: 2 }}>
                                                <Stepper
                                                    activeStep={activeStep}
                                                    orientation="vertical"
                                                >
                                                    {steps.map((label, index) => (
                                                        <Step key={label}>
                                                            <StepLabel>{label}</StepLabel>
                                                            <StepContent>
                                                                <Typography>
                                                                    {getStepContent(index)}
                                                                </Typography>
                                                                <div
                                                                    className={classes.actionsContainer}
                                                                >
                                                                    <div>
                                                                        <Button
                                                                            disabled={activeStep === 0}
                                                                            onClick={handleBack}
                                                                            className={classes.button}
                                                                        >
                                                                            Indietro
                                                                        </Button>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={handleNext}
                                                                            className={classes.button}
                                                                        >
                                                                            {activeStep === steps.length - 1
                                                                                ? "Fine"
                                                                                : "Prossimo"}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </StepContent>
                                                        </Step>
                                                    ))}
                                                </Stepper>
                                                {activeStep === steps.length && (
                                                    <Box sx={{ mt: 2 }}>
                                                        <Typography>
                                                            Tutti i documenti sono stati caricati
                                                        </Typography>
                                                        <Button
                                                            onClick={handleReset}
                                                            className={classes.button}
                                                        >
                                                            Ricomincia
                                                        </Button>
                                                    </Box>
                                                )}
                                            </Box>

                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    mt: 3,
                                }}
                            >
                                {isSubmitting ? (
                                    <CircularProgress size={24} disableShrink />
                                ) : (
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                    >
                                        Completa richiesta
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default CompletaRichiesta;