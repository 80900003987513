import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Chip,
    ChipClassKey,
    Container,
    Grid,
    IconButton,
    Link,
    Tooltip,
    Typography,
    TextField,
    makeStyles,
    createStyles,
    Theme,
    CardHeader,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { FC, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import useSettings from "src/hooks/useSettings";
import { useSelector as useReduxSelector } from "../../store";
import { loadRichiesteAnonime } from "./commands.richiestaAnonima";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ChevronRightIcon from "../../icons/ChevronRight";
import {
    Table,
    SelectColumnFilter,
    SelectColumnFilterMethod,
    DatePickerRangeFilter,
    DatePickerRangeFilterMethod,
} from "../../components/Table/Table";
import Scrollbar from "src/material/Scrollbar";
import { Credentials, Filters, FiltersRic } from "src/types/generated";
import moment from "moment";
import { padding } from "@material-ui/system";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowRightIcon from "../../icons/ArrowRight";
import AddIcon from "@material-ui/icons/Add";
import { rinunciaRichiesta } from "src/graphql/generated/queries";
import GraphqlClient from "src/client/graphql.client";
import { useSnackbar } from "notistack";
import _, { last } from "lodash";
import { NoFragmentCyclesRule } from "graphql";
import { Autocomplete } from "src/components/Autocomplete/Autocomplete";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import CircularProgress from "@material-ui/core/CircularProgress";

import ClearIcon from "@material-ui/icons/Clear";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";

const PaginaRichiesteAnonime: FC = (props) => {
    const state = useReduxSelector((state) => state.richiesteAnonime);
    const configuration = useReduxSelector(
        (state) => state.configuration.itemsMap
    );

    const configurationForSelect = useReduxSelector(
        (state) => state.configuration
    );
    const arrayStatoRichiesta =
        (configurationForSelect?.itemsForSelects &&
            configurationForSelect?.itemsForSelects["statoRichiesta"]) ||
        [];
    const arrayStatoPratica =
        (configurationForSelect?.itemsForSelects &&
            configurationForSelect?.itemsForSelects["statoPratica"]) ||
        [];
    const arrayTipoImpiego =
        (configurationForSelect?.itemsForSelects &&
            configurationForSelect?.itemsForSelects["tipoImpiego"]) ||
        [];

    const { settings } = useSettings();

    const { enqueueSnackbar } = useSnackbar();

    const { ...other } = props;

    const [dataDa, setDataDa] = useState(null);
    const [dataA, setDataA] = useState(null);
    const [codicePratica, setcodicePratica] = useState(null);
    const [nomeCognomeState, setNomeCognomeState] = useState(null);
    let nomeCognome = null;
    const [nome, setNome] = useState(null);
    const [cognome, setCognome] = useState(null);
    const [tipoImpiego, setTipoImpiego] = useState(null);
    const [statoRichiesta, setStatoRichiesta] = useState(null);
    const [statoPratica, setStatoPratica] = useState(null);
    const [debouncedState, setDebouncedState] = useState({} as FiltersRic);

    const [loading, setLoading] = useState(false);
    const [loadingRinuncia, setLoadingRinuncia] = useState(false);

    let filters: FiltersRic = {
        DataDa: dataDa,
        DataA: dataA,
        CodicePratica: codicePratica,
        Nome: nome,
        Cognome: cognome,
        StatoPratica: statoPratica,
        StatoRichiesta: statoRichiesta,
        TipoImpiego: tipoImpiego,
    };

    const splitta = () => {
        let str = nomeCognome;
        let strings = str.split(" ");
        setNome(strings[1]);
        filters.Nome = strings[1];
        setCognome(strings[0]);
        filters.Cognome = strings[0];
    };

    const debounce = useCallback(
        _.debounce((_searchVal: FiltersRic) => {
            setDebouncedState(_searchVal);
            loadRichiesteAnonime(_searchVal);
            setLoading(false);
        }, 1500),
        []
    );

    useEffect(() => {
        loadRichiesteAnonime(filters);
    }, []);

    const rinunciaRichiesta = async () => {
        const { richiestaId, codice } = lastId;
        let nota = "rinuncia richiesta " + codice;
        //console.log(nota)
        try {
            setLoadingRinuncia(true);
            await GraphqlClient.rinunciaRichiesta({ richiestaId, nota: nota });
            enqueueSnackbar("Richiesta " + codice + " eliminata", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "success",
            });
            handleClose();
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Impossibile eliminare richiesta " + codice, {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "error",
            });
        } finally {
            setLoadingRinuncia(false);
        }
    };

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            filters: {
                width: 130,
            },
        })
    );

    const style = useStyles();

    function isSameDay(a, b) {
        if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
        // Compare least significant, most likely to change units first
        // Moment's isSame clones moment inputs and is a tad slow
        return (
            a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
        );
    }

    const DatePickerRangeFilter = () => {
        const theme = useTheme();

        const Styles = styled.div`
        .CalendarDay__selected_span {
          background: #4e75e1;
          color: white;
          border: 1px solid;
        }
  
        .CalendarDay__selected {
          background: #27293d;
          color: white;
        }
  
        .CalendarDay__selected:hover {
          background: white;
          color: black;
        }
  
        .CalendarDay__hovered_span:hover,
        .CalendarDay__hovered_span {
          background: #4e75e1;
          color: #ffffff;
        }
  
        .CalendarDay__hovered_span_3 {
          color: #ffffff;
        }
  
        .DateRangePicker {
          border-radius: ${theme.shape.borderRadius}px;
          border: 1px solid rgb(79, 86, 95) !important;
          width: 85%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
        }
        .DateRangePicker:hover {
          border-color: #ffffff !important;
        }
        .DateRangePicker div {
          border-radius: 0.4285rem;
        }
        .DateRangePicker_picker {
          z-index: 99999 !important;
        }
        .DateRangePickerInput {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border: 0;
          border-radius: 0.4285rem;
          background-color: inherit;
          height: 37px;
        }
        .DateInput {
          margin: 0;
          padding: 0;
          position: relative;
          display: inline-block;
          width: 50%;
          vertical-align: middle;
          color: #ffffff;
          height: 35px !important;
          background-color: inherit;
        }
        .DateInput_input {
          font-weight: 200;
          font-size: 19px;
          border: 0px;
          text-align: center;
          box-shadow: none;
          width: 90%;
          height: 32px !important;
          padding: 0px !important;
          border-radius: ${theme.shape.borderRadius}px;
          color: #919eab;
          font-size: 0.875rem;
          text-decoration-color: #000000;
          background-color: inherit;
        }
        .DateInput_input::placeholder {
          color: #919eab;
        }
        .DateRangePickerInput__withBorder {
          color: #ffffff;
        }
        DateRangePicker_picker__fullScreenPortal {
          background: linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
        }
        .DayPicker_transitionContainer {
          position: relative;
          overflow: hidden;
          border-radius: 3px;
        }
        .DateRangePickerInput_arrow {
          display: flex;
          align-items: center;
          color: #ffffff52 !important;
          width: 28px;
        }
        .DateRangePickerInput_arrow_svg {
          vertical-align: middle;
          height: 24px;
          width: 24px;
        }
        .CalendarDay > td {
          height: 10px;
          width: 10px;
        }
      `;

        const LightStyle = styled.div`
        .CalendarDay__selected_span {
          background: #4e75e1;
          color: white;
          border: 1px solid;
        }
  
        .CalendarDay__selected {
          background: #27293d;
          color: white;
        }
  
        .CalendarDay__selected:hover {
          background: white;
          color: black;
        }
  
        .CalendarDay__hovered_span:hover,
        .CalendarDay__hovered_span {
          background: #4e75e1;
          color: #ffffff;
        }
  
        .CalendarDay__hovered_span_3 {
          color: #ffffff;
        }
  
        .DateRangePicker {
          border-radius: ${theme.shape.borderRadius}px;
          border: 1px solid #80808073 !important;
          width: 85%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
        }
  
        .DateRangePicker:hover {
          border-color: black !important;
        }
  
        .DateRangePicker div {
          border-radius: 0.4285rem;
        }
  
        .DateRangePicker_picker {
          z-index: 99999 !important;
        }
  
        .DateRangePickerInput {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border: 0;
          border-radius: 0.4285rem;
          background-color: inherit;
          height: 38px;
        }
  
        .DateInput {
          margin: 0;
          padding: 0;
          position: relative;
          display: inline-block;
          width: 100%;
          vertical-align: middle;
          color: #ffffff;
          height: 100%;
          background-color: inherit;
        }
  
        .DateInput_input {
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
            Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
          font-weight: 400;
          font-size: 1rem;
          border: 0px;
          text-align: center;
          box-shadow: none;
          width: 100%;
          height: 100%;
          padding: 4px 0px 5px;
          border-radius: ${theme.shape.borderRadius}px;
          color: #919eab;
          text-decoration-color: #000000;
          background-color: inherit;
        }
  
        .DateInput_input::placeholder {
          color: #6b778c;
        }
  
        .DateRangePickerInput__withBorder {
          color: #ffffff;
        }
  
        DateRangePicker_picker__fullScreenPortal {
          background: linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
        }
  
        .DayPicker_transitionContainer {
          position: relative;
          overflow: hidden;
          border-radius: 3px;
        }
  
        .DateRangePickerInput_arrow {
          display: flex;
          align-items: center;
          color: grey;
          width: 28px;
        }
  
        .DateRangePickerInput_arrow_svg {
          vertical-align: middle;
          height: 24px;
          width: 24px;
        }
  
        .CalendarDay > td {
          height: 10px;
          width: 10px;
        }
      `;

        const { t } = useTranslation();
        const { i18n } = useTranslation();
        new Date();
        var momDate = moment();
        var firstDay = moment().startOf("month");
        var lastDay = moment().endOf("month");
        var firstWeekDay = moment().startOf("week");
        var lastWeekDay = moment().endOf("week");
        var previousFirstDay = moment().startOf("month").subtract(1, "month");
        var previousLastDay = moment().subtract(1, "month").endOf("month");
        var nextFirstDay = moment().startOf("month").add(1, "month");
        var nextLastDay = moment().add(1, "month").endOf("month");
        moment().startOf("year");
        moment().endOf("year");
        var yesterday = moment().subtract(1, "days");
        var tomorrow = moment().add(1, "days");

        var presetOpt = {
            startDate: null,
            endDate: null,
            presets: [
                {
                    label: t("today"),
                    startDate: momDate,
                    endDate: momDate,
                },
                {
                    label: t("yesterday"),
                    startDate: yesterday,
                    endDate: yesterday,
                },
                {
                    label: t("tomorrow"),
                    startDate: tomorrow,
                    endDate: tomorrow,
                },
                {
                    label: t("week"),
                    startDate: firstWeekDay,
                    endDate: lastWeekDay,
                },
                {
                    label: t("month"),
                    startDate: firstDay,
                    endDate: lastDay,
                },
                {
                    label: t("lastMonth"),
                    startDate: previousFirstDay,
                    endDate: previousLastDay,
                },
                {
                    label: t("nextMonth"),
                    startDate: nextFirstDay,
                    endDate: nextLastDay,
                },
            ],
        };

        const [startDate, setStartDate] = useState();
        const [endDate, setEndDate] = useState();
        const [focusedInput, setFocusedInput] = useState(false);

        useEffect(() => {
            moment.locale(i18n.language);
        }, [t]);

        const renderDatePresets = () => {
            return (
                <div>
                    {presetOpt.presets.map(({ label, startDate, endDate }) => {
                        const isSelected =
                            isSameDay(presetOpt.startDate, startDate) &&
                            isSameDay(presetOpt.endDate, endDate);
                        let className = "";
                        if (!isSelected) {
                            className = "btn-simple";
                        }
                        return (
                            <Button
                                size="small"
                                className={className}
                                color="primary"
                                variant="text"
                                key={label}
                                onClick={() => {
                                    onDatesChange({ startDate: startDate, endDate: endDate });
                                    setFocusedInput(false);
                                }}
                            >
                                {label}
                            </Button>
                        );
                    })}
                </div>
            );
        };

        const onDatesChange = ({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
            if (startDate != null && endDate != null) {
                filters.DataDa = moment(startDate).format("YYYY-MM-DDTHH:mm:ss");
                filters.DataA = moment(endDate).format("YYYY-MM-DDTHH:mm:ss");
                setDataDa(moment(startDate).format("YYYY-MM-DDTHH:mm:ss"));
                setDataA(moment(endDate).format("YYYYY-MM-DDTHH:mm:ss"));
            }
            setLoading(true);
            loadRichiesteAnonime(filters);
            setLoading(false);
        };

        switch (theme.palette.mode) {
            case "dark":
                return (
                    <>
                        <Styles>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-around",
                                }}
                            >
                                <DateRangePicker
                                    startDatePlaceholderText={t("startDate")}
                                    endDatePlaceholderText={t("endDate")}
                                    startDate={startDate}
                                    onDatesChange={onDatesChange}
                                    startDateId={"startDate"}
                                    endDate={endDate}
                                    renderCalendarInfo={renderDatePresets}
                                    preset
                                    minimumNights={0}
                                    hideKeyboardShortcutsPanel
                                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={(focusedInput) =>
                                        setFocusedInput(focusedInput)
                                    } // PropTypes.func.isRequired,
                                    isOutsideRange={() => false}
                                    customArrowIcon={
                                        <ArrowForwardIosIcon style={{ padding: "4px" }} />
                                    }
                                />
                                <IconButton
                                    onClick={() => {
                                        setStartDate(undefined);
                                        setEndDate(undefined);
                                        setDataA(null);
                                        setDataDa(null);
                                        filters.DataA = null;
                                        filters.DataDa = null;
                                        setLoading(true);
                                        loadRichiesteAnonime(filters);
                                        setLoading(false);
                                    }}
                                    size="small"
                                    className="iconFilterDate"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </div>
                        </Styles>
                    </>
                );

            case "light":
                return (
                    <>
                        <LightStyle>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-around",
                                }}
                            >
                                <DateRangePicker
                                    startDatePlaceholderText={t("startDate")}
                                    endDatePlaceholderText={t("endDate")}
                                    startDate={startDate}
                                    onDatesChange={onDatesChange}
                                    startDateId={"startDate"}
                                    endDate={endDate}
                                    renderCalendarInfo={renderDatePresets}
                                    preset
                                    minimumNights={0}
                                    hideKeyboardShortcutsPanel
                                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={(focusedInput) =>
                                        setFocusedInput(focusedInput)
                                    } // PropTypes.func.isRequired,
                                    isOutsideRange={() => false}
                                    customArrowIcon={
                                        <ArrowForwardIosIcon style={{ padding: "4px" }} />
                                    }
                                />
                                <IconButton
                                    onClick={() => {
                                        setStartDate(undefined);
                                        setEndDate(undefined);
                                        setDataA(null);
                                        setDataDa(null);
                                        filters.DataA = null;
                                        filters.DataDa = null;
                                        setLoading(true);
                                        loadRichiesteAnonime(filters);
                                        setLoading(false);
                                    }}
                                    size="small"
                                    className="iconFilterDate"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </div>
                        </LightStyle>
                    </>
                );
        }
    };

    const boxFilterRemote = () => {
        let filtri = [
            <>
                <TextField
                    className={style.filters}
                    label="Codice"
                    value={codicePratica}
                    size="small"
                    onChange={(e) => {
                        filters.CodicePratica = e.target.value;
                        setcodicePratica(e.target.value);
                        setLoading(true);
                        debounce(filters);
                    }}
                />
            </>,
            <></>,
            <>
                <TextField
                    style={{
                        width: 150,
                    }}
                    label="Cognome e nome"
                    value={nomeCognomeState}
                    size="small"
                    onChange={(e) => {
                        nomeCognome = e.target.value;
                        splitta();
                        setNomeCognomeState(e.target.value);
                        setLoading(true);
                        debounce(filters);
                    }}
                />
            </>,
            <>
                <Autocomplete
                    id="tipoImpiego"
                    options={arrayTipoImpiego}
                    getOptionLabel={(option: any) => option.description}
                    onChange={(e, value: any) => {
                        setTipoImpiego(value?.codice);
                        filters.TipoImpiego = value?.codice;
                        setLoading(true);
                        debounce(filters);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={style.filters}
                            size="small"
                            label="Tipo"
                            name="tipoImpiego"
                            value={tipoImpiego}
                            variant="outlined"
                        />
                    )}
                />
            </>,
            <></>,
            <>
                <Autocomplete
                    id="statoRichiesta"
                    options={arrayStatoRichiesta}
                    getOptionLabel={(option: any) => option.description}
                    onChange={(e, value: any) => {
                        setStatoRichiesta(value?.codice);
                        filters.StatoRichiesta = value?.codice;
                        setLoading(true);
                        debounce(filters);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={style.filters}
                            size="small"
                            label="Stato"
                            name="statoRicheista"
                            value={statoRichiesta}
                            variant="outlined"
                        />
                    )}
                />
            </>,
            <>
                <Autocomplete
                    id="statoPratica"
                    options={arrayStatoPratica}
                    getOptionLabel={(option: any) => option.description}
                    onChange={(e, value: any) => {
                        setStatoPratica(value?.codice);
                        filters.StatoPratica = value?.codice;
                        setLoading(true);
                        debounce(filters);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={style.filters}
                            size="small"
                            label="Stato"
                            name="statoPratica"
                            value={statoPratica}
                            variant="outlined"
                        />
                    )}
                />
            </>,
        ];
        return filtri;
    };
    const filtriDate = () => {
        return (
            <>
                <Box
                    sx={{ m: 2, mt: 2 }}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                >
                    {DatePickerRangeFilter()}
                    {loading ? <CircularProgress disableShrink /> : ""}
                </Box>
            </>
        );
    };

    const [open, setOpen] = useState(false);
    const [lastId, setLastId] = useState({} as any);

    const handleClickOpen = (id) => {
        setLastId(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Helmet>
                <title>Richieste</title>
            </Helmet>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Conferma necessaria"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sei sicuro di voler procedere con l'operazione?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loadingRinuncia}
                        onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button
                        disabled={loadingRinuncia}
                        onClick={() => rinunciaRichiesta()}
                        color="secondary"
                        autoFocus
                    >
                        Si
                    </Button>
                </DialogActions>
            </Dialog>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    minHeight: "100%",
                    py: 8,
                }}
            >
                <Container maxWidth={settings.compact ? "xl" : false}>
                    <Grid container justifyContent="space-between" spacing={3}>
                        <Grid item>
                            <Typography color="textPrimary" variant="h5">
                                Pratiche
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Typography color="textSecondary" variant="subtitle2">
                                    Richieste anonime
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<AddIcon fontSize="small" />}
                                    sx={{ mt: 1 }}
                                    to="/richiesteAnonime/nuovaRichiestaAnonima"
                                    variant="contained"
                                >
                                    Nuova Richiesta Anonima
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Card {...other}>
                            {/* <CardHeader
                                  action={
                                      <IconButton>
                                          <CircularProgress disableShrink/>
                                      </IconButton>
                                  }
                              /> */}
                            <Scrollbar>
                                {filtriDate()}
                                <Box /* sx={{ minWidth: 1200 }} */>
                                    <Table
                                        remoteFilter={boxFilterRemote()}
                                        idDefaultColumnSort="num"
                                        Data={state?.data || []}
                                        Columns={[
                                            {
                                                Header: "Numero",
                                                accessor: "codice",
                                                id: "num",
                                                width: 140,
                                                disableFilters: true,
                                                Cell: (row) => (
                                                    <div>
                                                        <p>{row.row.original.codice}</p>
                                                        <p
                                                            style={{
                                                                color: "grey",
                                                                fontSize: "14px",
                                                                paddingTop: "2px",
                                                            }}
                                                        >
                                                            {moment(
                                                                new Date(row.row.original.dataUltimaModifica)
                                                            ).format("DD MMM YYYY | HH:mm")}
                                                        </p>
                                                    </div>
                                                ),
                                            },
                                            {
                                                Header: "Utente",
                                                accessor: "fullnameUtente",
                                                width: 120,
                                                disableFilters: true,
                                                Cell: (row) => {
                                                    let str = row.row.original.fullnameUtente;
                                                    let splitStr = str.toLowerCase().split(" ");
                                                    for (let i = 0; i < splitStr.length; i++) {
                                                        splitStr[i] =
                                                            splitStr[i].charAt(0).toUpperCase() +
                                                            splitStr[i].substring(1);
                                                    }
                                                    return (
                                                        <>
                                                            <p>{splitStr.join(" ")}</p>
                                                            <p
                                                                style={{
                                                                    color: "grey",
                                                                    fontSize: "13px",
                                                                    paddingTop: "2px",
                                                                }}
                                                            >
                                                                Agenzia di Roma
                                                                {/* MANCANO I CODICI FISCALI !!!!!! */}
                                                            </p>
                                                        </>
                                                    );
                                                },
                                            },
                                            {
                                                Header: "Tipo impiego",
                                                accessor: "tipoImpiego",
                                                width: 130,
                                                disableFilters: true,
                                                Cell: (row) => {
                                                    return (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                height: "100%",
                                                            }}
                                                        >
                                                            {configuration["tipoImpiego"]
                                                                ? configuration["tipoImpiego"][
                                                                row.row.original.tipoImpiego
                                                                ]
                                                                : row.row.original.tipoImpiego}
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                Header: "Importo richiesto",
                                                accessor: "importoRichiesto",
                                                width: 145,
                                                disableFilters: true,
                                                Cell: (row) => (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            height: "100%",
                                                        }}
                                                    >
                                                        {new Intl.NumberFormat("it-IT", {
                                                            style: "currency",
                                                            currency: "EUR",
                                                        }).format(row.row.original.importoRichiesto)}
                                                    </div>
                                                ),
                                            },
                                            {
                                                Header: "Stato Richiesta",
                                                accessor: "statoRichiesta",
                                                width: 130,
                                                disableFilters: true,
                                                Cell: (row) => {
                                                    return (
                                                        <Chip
                                                            label={
                                                                configuration["statoRichiesta"]
                                                                    ? configuration["statoRichiesta"][
                                                                    row.row.original.statoRichiesta
                                                                    ]
                                                                    : row.row.original.statoRichiesta
                                                            }
                                                            style={{ backgroundColor: "#2196f3\n" }}
                                                        />
                                                    );
                                                },
                                            },
                                            {
                                                Header: "Azioni",
                                                width: 120,
                                                disableFilters: true,
                                                Cell: (row) => {
                                                    let isDisabled = false;
                                                    if (row.row.original.statoRichiesta === 98) {
                                                        isDisabled = true;
                                                    }
                                                    return (
                                                        <>
                                                            <Tooltip title={"Elimina"}>
                                                                <IconButton
                                                                    aria-label="Elimina"
                                                                    color="secondary"
                                                                    onClick={() => {
                                                                        let { richiestaId, codice } =
                                                                            row.row.original;
                                                                        handleClickOpen({ richiestaId, codice });
                                                                    }}
                                                                    disabled={isDisabled}
                                                                >
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={"Dettaglio"}>
                                                                <IconButton
                                                                    aria-label="Dettagli"
                                                                    component={RouterLink}
                                                                    to={`/richiesteAnonime/dettagli/${row.row.original.richiestaId}/${row.row.original.macroStatoRichiesta}/${row.row.original.statoPratica}`}
                                                                >
                                                                    <ArrowRightIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    );
                                                },
                                            },
                                        ]}
                                    />
                                </Box>
                            </Scrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default PaginaRichiesteAnonime;
