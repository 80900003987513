import React, { useState } from "react";
import type { FC } from "react";
import {
    Link as RouterLink,
    useNavigate,
    useLocation,
    useParams,
} from "react-router-dom";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Tabs,
    Tab,
    CardHeader,
    CircularProgress,
    Container,
    Grid,
    Link,
    Select,
    TextField,
    Typography,
    AutocompleteRenderGroupParams,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
} from "@material-ui/core";
import useSettings from "../../hooks/useSettings";
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { FilePondErrorDescription, FilePondFile } from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { VariableSizeList, ListChildComponentProps } from "react-window";

import { useSelector as useReduxSelector } from "../../store";

import JSZip, { folder } from "jszip";

import {
    makeStyles,
    Theme,
    createStyles,
    useTheme,
} from "@material-ui/core/styles";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { DatePicker, LocalizationProvider } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import itLocale from "date-fns/locale/it";
import { AllegatiConIntero, FileConIntero, InputRichiestaPreventiviAnonimi } from "src/types/generated";
import moment from "moment";
import { creaRichiestaAnonima } from "./commands.richiestaAnonima";

// filepond plugin
registerPlugin(FilePondPluginFileValidateType);

const NuovaRichiestaAnonima: FC = (props) => {
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { enqueueSnackbar } = useSnackbar();

    const configuration = useReduxSelector((state) => state.configuration);
    const [codiceRichiesta, setCodiceRichiesta] = useState("");
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

    const blobToBase64 = async (blob: Blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
            if (typeof reader.result === "string") {
                return reader.result;
            }
        }
        return "";
    }

    const arrayTipoImpiego =
        (configuration?.itemsForSelects &&
            configuration?.itemsForSelects["tipoImpiegoSemplificato"]) ||
        [];

    return (
        <>
            <Helmet>
                <title>Nuova Richiesta Anonima</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    minHeight: "100%",
                    py: 8,
                }}
            >
                <Container maxWidth={settings.compact ? "xl" : false}>
                    <Grid container justifyContent="space-between" spacing={3}>
                        <Grid item>
                            <Typography color="textPrimary" variant="h5">
                                Nuova richiesta anonima
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/richiesteAnonime"
                                    variant="subtitle2"
                                >
                                    Richieste anonime
                                </Link>
                                <Typography color="textSecondary" variant="subtitle2">
                                    Nuova richiesta anonima
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Dialog open={openSuccessDialog} onClose={() => setOpenSuccessDialog(false)}>
                            <DialogTitle>
                                <Typography variant="h6">
                                    Richiesta anonima creata con successo
                                </Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="body1">
                                    Codice richiesta: {codiceRichiesta}
                                </Typography>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setOpenSuccessDialog(false);
                                        navigate("/richiesteAnonime");
                                    }}
                                    variant="contained"
                                >
                                    Chiudi
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<ArrowLeftIcon fontSize="small" />}
                                    sx={{ mt: 1 }}
                                    to="/richiesteAnonime"
                                    variant="outlined"
                                >
                                    Indietro
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 3 }}>
                        <Formik
                            initialValues={{
                                dataNascita: "",
                                sesso: "",
                                importo: 0,
                                tipoImpiego: "",
                                dataAssunzione: "",
                                file: [],
                                nota: "",
                                submit: null,
                            }}
                            onSubmit={async (
                                values,
                                { setErrors, setStatus, setSubmitting }
                            ): Promise<void> => {
                                setSubmitting(true);

                                const {
                                    dataNascita,
                                    sesso,
                                    importo,
                                    tipoImpiego,
                                    dataAssunzione,
                                    file,
                                    nota,
                                } = values;

                                const inputForm: InputRichiestaPreventiviAnonimi = {
                                    dataNascita: dataNascita
                                        ? moment(new Date(dataNascita)).format(
                                            "YYYY-MM-DD"
                                        ) + "T00:00:00Z"
                                        : null,
                                    sesso: sesso,
                                    tipoImpiego: Number(tipoImpiego),
                                    dataAssunzione: dataAssunzione
                                        ? moment(new Date(dataAssunzione)).format(
                                            "YYYY-MM-DD"
                                        ) + "T00:00:00Z"
                                        : null,
                                    importoRichiesto: importo,
                                    nota: nota,
                                };

                                if (file.length) {
                                    const zip = new JSZip();
                                    file.forEach((file) => {
                                        zip.file(file.filename, file.file);
                                    });
                                    
                                    const content = await zip.generateAsync({ type: "base64" });

                                    const allegato: AllegatiConIntero = {
                                        base64zip: content,
                                        files: [{
                                            tipoDocumento: 140,
                                            nomeFile: file[0].filename,
                                            fileType: "pdf",
                                        }]
                                    }

                                    inputForm.allegati = allegato;

                                }

                                const res = await creaRichiestaAnonima(inputForm);
                                if (res.error) {
                                    console.error(res.message);

                                    let errore = "Errore generico";
                                    try {
                                        if (res.message.length > 0) {
                                            let err = JSON.parse(res.message[0].message).status;
                                            errore = `(${err.status})[${err.errorMessage}]`;
                                        }
                                    } catch (e1) {
                                        console.error("E!!!", e1);
                                    }

                                    enqueueSnackbar(
                                        "Impossibile creare la richiesta: " + errore,
                                        {
                                            anchorOrigin: {
                                                horizontal: "right",
                                                vertical: "top",
                                            },
                                            variant: "error",
                                        }
                                    );
                                    setSubmitting(false);
                                    return;
                                }

                                setCodiceRichiesta(res.data.codiceRichiesta);
                                setSubmitting(false);
                                setOpenSuccessDialog(true);
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values,
                            }): JSX.Element => (
                                <form onSubmit={handleSubmit} {...props}>
                                    <Box sx={{ p: 3 }}>
                                        <Grid container spacing={3}>
                                            <Grid item md={6} xs={12}>
                                                <Card>
                                                    <CardContent>
                                                        <CardHeader title="Dati cliente" />

                                                        <Box sx={{ mt: 2 }}>
                                                            <LocalizationProvider
                                                                dateAdapter={AdapterDateFns}
                                                                locale={itLocale}
                                                            >
                                                                <DatePicker
                                                                    openTo="year"
                                                                    views={["year", "month", "date"]}
                                                                    onChange={(date: Date | null): void => {
                                                                        setFieldValue("dataNascita", date);
                                                                    }}
                                                                    value={values.dataNascita}
                                                                    renderInput={(props): JSX.Element => (
                                                                        <TextField
                                                                            {...props}
                                                                            error={Boolean(
                                                                                touched.dataNascita &&
                                                                                errors.dataNascita
                                                                            )}
                                                                            fullWidth
                                                                            helperText={
                                                                                touched.dataNascita &&
                                                                                errors.dataNascita
                                                                            }
                                                                            label="Data di nascita"
                                                                            name="dataNascita"
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            value={values.dataNascita}
                                                                            variant="outlined"
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Box>

                                                        <Box sx={{ mt: 2 }}>
                                                            <Autocomplete
                                                                id="sesso"
                                                                //name="sesso"
                                                                options={[
                                                                    { value: "M", title: "Uomo" },
                                                                    {
                                                                        value: "F",
                                                                        title: "Donna",
                                                                    },
                                                                ]}
                                                                getOptionLabel={(option) => option.title}
                                                                onChange={(e, value: any) => {
                                                                    //console.log(value);
                                                                    setFieldValue(
                                                                        "sesso",
                                                                        value !== null ? value.value : null
                                                                    );
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        error={Boolean(
                                                                            touched.sesso && errors.sesso
                                                                        )}
                                                                        fullWidth
                                                                        helperText={touched.sesso && errors.sesso}
                                                                        label="Sesso"
                                                                        name="sesso"
                                                                        onBlur={handleBlur}
                                                                        //onChange={handleChange}
                                                                        value={values.sesso}
                                                                        variant="outlined"
                                                                    />
                                                                )}
                                                            />
                                                        </Box>

                                                        <Box sx={{ mt: 2 }}>
                                                            <TextField
                                                                error={Boolean(touched.nota && errors.nota)}
                                                                fullWidth
                                                                helperText={touched.nota && errors.nota}
                                                                label="Nota"
                                                                name="nota"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.nota}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Card>
                                                    <CardContent>
                                                        <Box sx={{ mt: 2 }}>
                                                            <CardHeader title="Informazioni richiesta" />

                                                            <TextField
                                                                error={Boolean(
                                                                    touched.importo && errors.importo
                                                                )}
                                                                type={"number"}
                                                                fullWidth
                                                                helperText={touched.importo && errors.importo}
                                                                label="Importo"
                                                                name="importo"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.importo}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                        <Box sx={{ mt: 2 }}>
                                                            <Autocomplete
                                                                id="tipoImpiego"
                                                                options={arrayTipoImpiego}
                                                                getOptionLabel={(option: any) =>
                                                                    option.description
                                                                }
                                                                onChange={(e, value) => {
                                                                    //console.log(value);
                                                                    setFieldValue(
                                                                        "tipoImpiego",
                                                                        value !== null ? value.codice : null
                                                                    );
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        error={Boolean(
                                                                            touched.tipoImpiego && errors.tipoImpiego
                                                                        )}
                                                                        fullWidth
                                                                        helperText={
                                                                            touched.tipoImpiego && errors.tipoImpiego
                                                                        }
                                                                        label="Tipo impiego"
                                                                        name="tipoImpiego"
                                                                        value={values.tipoImpiego}
                                                                        variant="outlined"
                                                                    />
                                                                )}
                                                            />
                                                        </Box>
                                                        <Box sx={{ mt: 2 }}>
                                                            <LocalizationProvider
                                                                dateAdapter={AdapterDateFns}
                                                                locale={itLocale}
                                                            >
                                                                <DatePicker
                                                                    openTo="year"
                                                                    views={["year", "month", "date"]}
                                                                    onChange={(date: Date | null): void => {
                                                                        setFieldValue("dataAssunzione", date);
                                                                        console.log(date);
                                                                    }}
                                                                    value={values.dataAssunzione}
                                                                    renderInput={(props): JSX.Element => (
                                                                        <TextField
                                                                            {...props}
                                                                            error={Boolean(
                                                                                touched.dataAssunzione &&
                                                                                errors.dataAssunzione
                                                                            )}
                                                                            fullWidth
                                                                            helperText={
                                                                                touched.dataAssunzione &&
                                                                                errors.dataAssunzione
                                                                            }
                                                                            label="Data di assunzione"
                                                                            name="dataAssunzione"
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            value={values.dataAssunzione}
                                                                            variant="outlined"
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Box>

                                                        <Card variant="outlined" sx={{ mt: 2, px: 1 }}>
                                                            <CardHeader title="Documento richiesta" />
                                                            <FilePond
                                                                files={values.file}
                                                                allowMultiple={false}
                                                                onupdatefiles={(fileItems) => {
                                                                    setFieldValue(
                                                                        "file",
                                                                        fileItems
                                                                    );
                                                                }}
                                                                allowFileTypeValidation
                                                                fileValidateTypeLabelExpectedTypes="{allTypes}"
                                                                name="Documento"
                                                                acceptedFileTypes={["application/pdf"]}
                                                                labelIdle='Trascina e lascia il pdf o <span class="filepond--label-action">Cerca</span>'
                                                            />
                                                        </Card>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                mt: 3,
                                            }}
                                        >
                                            {isSubmitting ? (
                                                <CircularProgress size={24} disableShrink />
                                            ) : (
                                                <Button
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                >
                                                    Crea richiesta
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default NuovaRichiestaAnonima;
